export default {
    state: {
        successStatus: false,
    },
    mutations: {
        toggleStatus(state) {
            state.successStatus = !state.successStatus
            setTimeout(() => {
                state.successStatus = false
            }, 1800)
        }
    },
    getters: {
        getSuccessStatus(state) {
            return state.successStatus
        },
    }
}