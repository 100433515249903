import getRequests from "@/plugins/requests/getRequests";
import postRequest from "@/plugins/requests/postRequest";
import deleteRequest from "@/plugins/requests/deleteRequest";
import putRequest from "@/plugins/requests/putRequest";

export default {
    actions: {
        fetchProducts(context, menuId) {
            return getRequests('/products?menu=' + menuId, 'updateProducts', context)
        },
        fetchAllProductsName(context) {
            return getRequests('/products/all_name?pagination=false', 'updateAllProductsName', context)
        },
        addProduct(context, data) {
            return postRequest('/products', data, 'addProductMutation', context)
        },
        changeProduct(context, data) {
            return putRequest('/products/' + data.id, data.product, 'changeProductMutation', context)
        },
        deleteProduct(context, productId) {
            return deleteRequest('/products/' + productId, 'deleteProduct', context)
        },
    },

    mutations: {
        updateProducts(state, products) {
            state.products = products
        },
        updateAllProductsName(state, allProductsName) {
            state.allProductsName = allProductsName
        },
        addProductMutation(){},
        changeProductMutation(){},
        deleteProduct(){}
    },

    state: {
        products: {
            models: [],
            totalItems:0
        },
        allProductsName: {
            models: [],
            totalItems:0
        },
    },

    getters: {
        getProducts(state) {
            return state.products.models
        },
        getAllProductsName(state) {
            return state.allProductsName.models
        }
    }
}