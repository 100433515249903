<template>
    <div class="app">
        <HeaderComponent v-if="isAuthorized"/>
        <router-view/>
    </div>
    <StatusModalComponent v-if="getSuccessStatus" class="status-component"/>
</template>

<script>

import HeaderComponent from "@/components/HeaderComponent.vue";
import {mapGetters} from "vuex";
import StatusModalComponent from "@/components/StatusModalComponent.vue";

export default {
    name: 'App',
    components: {StatusModalComponent, HeaderComponent},
    computed: {
        ...mapGetters(['isAuthorized', 'getSuccessStatus']),
    },
    mounted() {
        if (!this.isAuthorized) {
            this.$root.$router.push({path: '/login'})
        }
    }
}
</script>

<style>
.side-bar {
    background: #333333;
}

.status-component {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
