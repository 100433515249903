<template>
    <img v-if="getSuccessStatus" class="success-modal" src="../assets/gifs/success.gif"/>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    name: "StatusModalComponent",
    computed: {
        ...mapGetters(['getSuccessStatus'])
    }
}

</script>

<style scoped>
    .success-modal {
        border-radius: 50%;
        height: 340px;
        width: 350px;
        border: solid 2px #12be12;
    }

    @media screen and (max-width: 500px) {
        .success-modal {
            height: 240px;
            width: 250px;
        }
    }

</style>