import postRequest from "@/plugins/requests/postRequest";
// import getRequest from "@/plugins/requests/getRequest";
import store from "@/plugins/vuex/store";
// import putRequest from "@/plugins/requests/putRequest";

export default {
    actions: {
        fetchAdminToken(context, data) {
            return postRequest('/users/auth', data, 'updateToken', context)
        },
        refreshAdminToken(context, data) {
            return postRequest('/users/auth/refreshToken', {refreshToken: data}, 'updateToken', context)
        },
        // fetchAboutMe(context){
        //     return getRequest('/users/about_me', 'updateUser', context)
        // },
        // pushUser(context, data) {
        //     return postRequest('/users', data, 'updateUser', context)
        // },
        // fetchIsUniqueEmail(context, data) {
        //     return postRequest('/users/is_unique_email', data, 'updateIsUniqueEmail', context)
        // },
        // changeUser(context, data) {
        //     return putRequest('/users/' + data.id, data.user, 'updateUser', context)
        // },
        // changeUserStatus(context, data) {
        //     return putRequest('/users/status/' + data.id, {"isOnline": data.status}, 'changeUser', context)
        // },
        // changePassword(context, data) {
        //     return putRequest('/users/' + data.id + '/password', data.user, 'changeUser', context)
        // },
        // fetchUserInfo(context, userId){
        //     return getRequest('/users/' + userId, 'updateUserInfo', context)
        // },
        // fetchRequestResetPassword(context, data) {
        //     return postRequest('/users/request_reset_password', data, 'changeUser', context)
        // },
        // fetchCheckResetToken(context, data) {
        //     return postRequest('/users/check_reset_token', data, 'changeUser', context)
        // },
        // fetchResetPassword(context, data) {
        //     return postRequest('/users/reset_password', data, 'changeUser', context)
        // },
        // changeUserActive(context, data) {
        //     return postRequest('/users/confirm_email', data, 'changeUser', context)
        // },
        // reSendEmailConfirm(context, data) {
        //     return postRequest('/users/resend_confirm_email', data, 'changeUser', context)
        // }
    },

    mutations: {
        updateToken(state, tokens) {
            localStorage.setItem('accessToken', tokens.accessToken)
            localStorage.setItem('refreshToken', tokens.refreshToken)

            state.accessToken = tokens.accessToken
            state.refreshToken = tokens.refreshToken
        },
        updateUser(state, user) {
            state.user = user
        },
        updateUserInfo(state, user) {
            localStorage.setItem('id', user.id)
            localStorage.setItem('givenName', user.givenName)
            localStorage.setItem('familyName', user.familyName)
            localStorage.setItem('isOnline', user.isOnline)
            state.userInfo = user
        },
        clearTokens(state) {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')

            state.accessToken = localStorage.getItem('accessToken')
            state.refreshToken = localStorage.getItem('refreshToken')
            state.isEmployer = localStorage.getItem('isEmployer')

            state.user = {
                id: null,
                email: null,
                givenName: null,
                familyName: null,
                roles: [],
                gender: null,
                additionalName: null,
                phone: null,
                isEmployer: null,
                availableCompanies: 0,
                isOnline: null,
                isActive: null,
                image: null,
            }
            store.commit('updateMyResumes', {models: []});
            store.commit('updateMyVacancies', {models: []});
            store.commit('updateMyCompanies', {models: []});

            console.log('Token CLEARED')
        },
        updateIsUniqueEmail(state, data) {
            state.isUniqueEmail = data.isUnique
        },
        changeUser() {},
    },

    state: {
        accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken'),

        user: {
            id: null,
            email: null,
            givenName: null,
            familyName: null,
            roles: [],
            gender: null,
            additionalName: null,
            phone: null,
            isEmployer: null,
            availableCompanies: 0,
            isOnline: null,
            isActive: null,
            image: null,
        },
        userInfo: {
            id: localStorage.getItem('id'),
            givenName: localStorage.getItem('givenName'),
            familyName: localStorage.getItem('familyName'),
            isOnline: localStorage.getItem('isOnline')
        },
        isUniqueEmail: null,
    },

    getters: {
        getAccessToken(state) {
            return state.accessToken
        },
        getRefreshToken(state) {
            return state.refreshToken
        },
        isAuthorized(state) {
            return state.accessToken !== null
        },
        isEmployer(state) {
          return state.user.isEmployer
        },
        getUser(state) {
            return state.user
        },
        getUserInfo(state) {
            return state.userInfo
        },
        getIsUniqueEmail(state) {
            return state.isUniqueEmail
        },
    }
}
