<template>
    <header>
        <nav class="navbar navbar-expand-md py-1">
            <div class="container-fluid ps-3">
                <router-link class="navbar-brand text-light" to="/">Chayxana41</router-link>
                <button class="navbar-toggler bg-light" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <div class="mx-auto">
                        <router-link class="user-name nav-link text-white" to="#">
                            <img width="27" src="../assets/img/avatar.svg"/>
                            Qarshiyev Ulug'bek
                        </router-link>
                    </div>
                    <ul class="radio-toolbar navbar-nav ps-0">
                        <li class="d-sm-none mt-3"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                        >
                            <input type="radio" id="radio1" name="radios" checked>
                            <label for="radio1" @click="this.$router.push('/orders');">Заказы</label>
                        </li>
                        <li class="d-sm-none"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                        >
                            <input type="radio" id="radio2" name="radios">
                            <label for="radio2" @click="this.$router.push('/messengers');">Сообщения</label>
                        </li>
                        <li class="d-sm-none"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                        >
                            <input type="radio" id="radio4" name="radios">
                            <label for="radio4"  @click="this.$router.push('/report')">Отчет</label>
                        </li>
                        <li class="d-sm-none">
                            <input type="radio" id="radio3" name="radios">
                            <label for="radio3" class="dropdown-toggle " data-bs-toggle="dropdown" aria-expanded="false">
                                Настройки сайт
                            </label>
                            <div class="dropdown">
                                <ul class="dropdown-menu">
                                    <li data-bs-toggle="collapse"
                                        data-bs-target="#navbarSupportedContent"
                                    >
                                        <span class="dropdown-item" @click="this.$router.push('/settings-menu')">Настройка меню</span>
                                    </li>
                                    <li data-bs-toggle="collapse"
                                        data-bs-target="#navbarSupportedContent"
                                    >
                                        <span class="dropdown-item" @click="this.$router.push('/settings-product/' + this.getMenus[0].id)">Настройка продукты</span>
                                    </li>
                                    <li data-bs-toggle="collapse"
                                        data-bs-target="#navbarSupportedContent"
                                    >
                                        <span class="dropdown-item" @click="this.$router.push('/settings-menu')">Настройка реклама</span>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="nav-item">
                            <div class="d-flex nav-link" role="search">
                                <div class="input-group">
                                    <input type="text" style="width: 135px" class="form-control" placeholder="Поиск"
                                    >
                                    <button class="btn bg-secondary-subtle" type="button" id="button-addon2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                            <path
                                                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "HeaderComponent",
    computed: { ...mapGetters(['getMenus']) },
    methods: { ...mapActions(['fetchMenus']) },
    mounted() { this.fetchMenus() }
}

</script>


<style scoped>
.user-name {
    font-size: 16px;
}

header {
    position: sticky;
    background-color: #FE5F00;
    padding-bottom: 1px;

}

.navbar-brand {
    font-weight: bold;
}

ul li {
    list-style: none;
}

.radio-toolbar label:hover {
    color: #000000;
    border-bottom-color: #000000;
}

.radio-toolbar input[type="radio"] {
    display: none;
}

.radio-toolbar label {
    display: inline-block;
    cursor: pointer;
    color: white;
    border-bottom: white solid 2px;
    margin-bottom: 20px;
    font-size: 16px;
    list-style: none;
    width: 100%;
}

.dropdown-item {
    cursor: pointer;
}
</style>