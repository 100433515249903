import getRequests from "@/plugins/requests/getRequests";
// import postRequest from "@/plugins/requests/postRequest";
// import deleteRequest from "@/plugins/requests/deleteRequest";
// import putRequest from "@/plugins/requests/putRequest";

export default {
    actions: {
        fetchOrders(context) {
            return getRequests('/orders', 'updateOrders', context)
        },
        // addMenu(context, data) {
        //     return postRequest('/menus', data, 'updateAddMenus', context)
        // },
        // changeMenu(context, data) {
        //     return putRequest('/menus/' + data.id, data.menu, 'updateChangeMenus', context)
        // },
        // deleteMenuAction(context, menuId) {
        //     return deleteRequest('/menus/' + menuId, context)
        // }
    },

    mutations: {
        updateOrders(state, orders) {
            state.orders = orders
        },
        // updateChangeMenus(){}
    },

    state: {
        orders: {
            models: [],
            totalItems:0
        }
    },

    getters: {
        getOrders(state) {
            return state.orders.models
        }
    }
}
