import getRequests from "@/plugins/requests/getRequests";
import postRequest from "@/plugins/requests/postRequest";
import deleteRequest from "@/plugins/requests/deleteRequest";
import putRequest from "@/plugins/requests/putRequest";

export default {
    actions: {
        fetchMenus(context) {
            return getRequests('/menus?pagination=false', 'updateMenus', context)
        },
        addMenu(context, data) {
            return postRequest('/menus', data, 'updateAddMenus', context)
        },
        changeMenu(context, data) {
            return putRequest('/menus/' + data.id, data.menu, 'updateChangeMenus', context)
        },
        deleteMenuAction(context, menuId) {
            return deleteRequest('/menus/' + menuId, context)
        }
    },

    mutations: {
        updateAddMenus() {},
        updateMenus(state, menus) {
            state.menus = menus
        },
        updateChangeMenus(){}
    },

    state: {
        menus: {
            models: [],
            totalItems:0
        }
    },

    getters: {
        getMenus(state) {
            return state.menus.models
        }
    }
}
